@use "sass:math";
// Custom Theming for Angular Material
@use '@angular/material' as mat;

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@layer components {
    .tw-card {
      @apply tw-flex tw-rounded-lg tw-shadow-lg tw-bg-white tw-p-4;
    }
  
    .tw-btn {
      @apply tw-rounded-md tw-bg-brand-default tw-py-2 tw-px-4 tw-border tw-border-transparent tw-text-center tw-text-sm tw-text-white tw-transition-all tw-shadow-md hover:tw-shadow-lg focus:tw-bg-brand-default focus:tw-shadow-none active:tw-bg-brand-default hover:tw-bg-brand-default active:tw-shadow-none disabled:tw-pointer-events-none disabled:tw-opacity-50 disabled:tw-shadow-none tw-ml-2; 
    }

    .tw-btn-error {
        @apply tw-rounded-md tw-bg-red-400 tw-py-2 tw-px-4 tw-border tw-border-transparent tw-text-center tw-text-sm tw-text-white tw-transition-all tw-shadow-md hover:tw-shadow-lg focus:tw-bg-red-400 focus:tw-shadow-none active:tw-bg-red-400 hover:tw-bg-red-400 active:tw-shadow-none disabled:tw-pointer-events-none disabled:tw-opacity-50 disabled:tw-shadow-none tw-ml-2;
    }
  
    .tw-label {
      @apply tw-inline-flex tw-py-1.5 tw-px-3 tw-text-center tw-rounded-md tw-text-white ;
    }
  
    .message {
      @apply tw-m-2 tw-p-2 tw-rounded-r-md tw-text-center tw-whitespace-pre-wrap;
    }

    .tw-list-label {
        @apply tw-text-slate-400;
      }
    
    .tw-list-value {
        @apply tw-text-gray-800;
    }
  
  }

@include mat.elevation-classes();
@include mat.app-background();

// For more information: https://material.angular.io/guide/theming
// @import "@angular/material/theming";

// Plus imports for other components in your app.
 @import '@angular/material/prebuilt-themes/indigo-pink.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
// @include mat.legacy-core();

// Define the palettes for your the using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$DDFinancePortal-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$DDFinancePortal-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$DDFinancePortal-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$DDFinancePortal-theme: mat.m2-define-light-theme($DDFinancePortal-primary, $DDFinancePortal-accent, $DDFinancePortal-warn);



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($DDFinancePortal-theme);
/* You can add global styles to this file, and also import other style files */


//@import "styles/custom.scss";
@import "styles/helpers.scss";
@import "styles/layout.scss";
//@import "styles/typography.scss";


html {
    height: 100%;
    font-size: 14px;
    overflow-x: hidden;
    overflow-y: auto;
}

body {
    min-height: 100%;
    font-family: Arial, serif;
    font-weight: normal;
    background-color: #f1f1f1;
    color: #1d3d6d;
}

#sidenav
{

    &.is-mobile
    {
        width: 100%;
    }

    a{
        color: #1d3d6d;
    }
}


.mat-tooltip { // making the font size on the mat-tooltip 1.5rem globally
    font-size: 14px;
    background-color: #1d3d6d;
    color: #ffffff;

    &.exaggerated-tooltip { // to modify the tooltip create a class like this
        font-size: 16px; // and use it like this: *matTooltipClass="exaggerated-tooltip"* in thecolor: red;         // component in which you are putting the tooltip

    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0.5rem 0;
}

p {
    margin: 0.5rem 0;
}

.wrapper {
    &.is-docked {
        padding-left: 350px;

        .wrapper-menu {
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            width: 350px;
            box-shadow: .2rem 0 .2rem 0 rgba(0, 0, 0, .02);
            z-index: 111;
        }
    }
}

header {
    padding: .75rem 0;
    background-color: #ffffff;

    &.is-transparent {
        background-color: transparent;
    }
}

.container {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;

    .container {
        max-width: inherit;
        padding-left: 0;
        padding-right: 0;
    }
}

.success {
    background-color: #4CAF50;
}

.info {
    background-color: #1d3d6d;
}


.approved {
    background-color: #000080;
}


.other {
    background-color: #e7e7e7;
    color: black;
}

@media only screen and (min-width: 960px) {
    .optionbar
    {
        max-width: 325px !important;
    }
}

.optionbar:empty {
    display: none;
}

.card {
    min-height: 100%;
    display: flex;
    padding: 2px;
    flex-direction: column;
    align-items: stretch;
    border-radius: 0.025rem;
    background-color: #ffffff;
    box-shadow: 0 .1rem .2rem 0 rgba(0, 0, 0, 0.1);

    .card-head {
        flex-shrink: 0;
        padding: 1rem 1rem 0 1rem;
        text-transform: uppercase;

        .card-title {
            .mat-icon {
                margin-right: .3rem;
                vertical-align: bottom;
            }
        }
    }

    .card-body {
        flex-grow: 1;
        flex-shrink: 0;
        padding: .5rem 1rem;
    }

    .card-footer {
        flex-shrink: 0;
        padding: 1rem;
    }


}

header.card {
    padding: 1.5rem;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid rgba(200, 200, 200, .3);
}

.mat-slide-toggle.mat-checked {
    .mat-slide-toggle-bar {
        background-color: #6a83a8;
    }

    .mat-slide-toggle-thumb {
        background-color: #1d3d6d;
    }
}

.mat-card:not([class*="mat-elevation-z"]) {
    box-shadow: none;
}

.responsive-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.icon-label {
    margin: 0;
    padding: 0;
    line-height: 0rem;
    font-size: .7rem;
    color: inherit;
    text-transform: uppercase;
    opacity: .5;
}


.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-scrollable {
    display: block;
    height: 500px;
    width: 100%;
    overflow-y:scroll;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

table.table {
    width: 100%;
    margin-top: 1rem;
    border-collapse: collapse;

    &.is-report
    {
        font-size: 0.8rem;

        tbody {
            tr:hover {
                background-color: transparent !important;
            }
        }
    }

    thead {
        th,
        td {
            padding: 1rem 0.5rem;
            border-bottom: 1px solid rgba(200, 200, 200, .3);
            text-transform: uppercase;
            opacity: 0.5;
        }
    }

    tbody {
        tr:hover {
            background-color: rgba(0, 0, 0, .03);
        }

        tr:last-child td {
            border-bottom: 0;
        }

        td {
            padding: 0.5rem;
            border-bottom: 1px solid rgba(200, 200, 200, .3);
        }
    }

    tfoot
    {
        font-weight: bold;

        td{
            padding: 1rem 0.5rem;
            border-top: 1px solid rgba(200, 200, 200, .3);
            text-transform: uppercase;
        }
    }
}

.label {
    display: inline-block;
    min-width: 5rem;
    padding: .2rem .4rem;
    text-align: center;
    border-radius: .2rem;
    color: #fff;

    &.black {
        background-color: #444;
    }

    &.green {
        background-color: #92CF5C;
    }

    &.accent-green {
        background-color: #69F0AE;
    }

    &.orange {
        background-color: #FFB300;
    }

    &.red {
        background-color: #E33244;
    }
    &.blue
    {
        background-color: #1d3d6d;
        color: #fff;
    }

}

.level-item {
    text-align: center;
    text-transform: uppercase !important;

    h3 {
        opacity: .6;
        font-size: .8rem;
        font-weight: normal;
        color: #222 !important;
    }

    h4 {
        font-size: 1.5rem;
        font-weight: normal;
        color: #222 !important;
    }
}


.separator {
    font-size: 15px;
    font-weight: 600;
    margin: 24px auto;
    position: relative;
    overflow: hidden;
    width: 100px;
    text-align: center;

    .text {
        display: inline-flex;
        position: relative;
        padding: 0 8px;
        z-index: 9999;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 30px;
            position: absolute;
            top: 10px;
            border-top: 1px solid;
        }

        &:before {
            right: 100%;
        }

        &:after {
            left: 100%;
        }
    }
}

.dl-separator {
    margin-top: 2rem;
    padding: .5rem;
    border-radius: .2rem;
    background-color: #f1f1f1;
}

.layout-padding {
    margin-left: -15px;
    margin-right: -15px;
}

.is-active {
    color: mat.m2-get-color-from-palette($DDFinancePortal-primary) !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-move
{
    cursor: move;
}

.mat-list-item {
    font-size: .9rem !important;
}

.navbar {
    .mat-tab-nav-bar {
        border-bottom: 0;
    }

    background-color: #f9f9f9;
    box-shadow: 0 .1rem .2rem 0 rgba(0, 0, 0, 0.1);

    a {
        text-transform: uppercase;
        text-decoration: none;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.menu {
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0 .1rem .2rem 0 rgba(0, 0, 0, 0.1);
    animation: fadeIn .2s linear forwards;

    .menu-header {
        padding: 1rem;
        background-color: #00529B !important;
        color: #BDE5F8 !important;
        text-align: center;
        text-transform: uppercase;

        h2 {
            font-size: 1.2rem;
            color: #f1f1f1 !important;

            small {
                font-size: .9rem;
            }
        }
    }

    .menu-links {
        min-height: 400px;
        padding: 1rem;
        text-align: center;
        text-transform: uppercase;

        button {
            line-height: 2.2rem;
            text-decoration: none;
            background: transparent;
            text-align: center;

            &.is-active {
                color: #00529B;

                .mat-icon {
                    color: #00529B;
                }
            }
        }
    }

    .menu-settings {
        min-height: 400px;
        padding: 1rem;
    }
}

.ussd-content {
    height: auto;
    margin: 1rem 0;
    border-radius: .2rem;
    color: black;
    overflow: auto;
}

.select-button {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    padding: 8px !important;
    text-align: left !important;
    text-transform: initial !important;
    width: 100% !important;
    box-sizing: border-box !important;
    margin-bottom: 1.2rem;
    border: 1px solid #ddd;
    color: inherit;
}

.message {
    margin: .5rem 0;
    padding: .5rem;
    border-radius: .2rem;
    white-space: pre-wrap;
    text-align: center;

    &.is-info {
        color: #00529B !important;
        background-color: #BDE5F8 !important;
    }

    &.is-success {
        color: #4F8A10 !important;
        background-color: #DFF2BF !important;
    }

    &.is-warning {
        color: #9F6000 !important;
        background-color: #FEEFB3 !important;
    }

    &.is-error {
        color: #D8000C !important;
        background-color: #FFD2D2 !important;
    }
}

.spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


.chat-window {
    height: 300px;
    overflow-x: none;
    overflow-y: scroll;

    .chat-line {
        margin: .5rem;
        padding: .5rem;
        border-radius: .2rem;
        background-color: #f9f9f9;
        white-space: pre-line;
        list-style: none;

        strong {
            background-color: #92CF5C;
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.mat-drawer-container
{
    background-color: transparent !important;
    color: inherit !important;
}

.dd-flex {
    display: flex;
}

.drag-list
{
    overflow: hidden;

    .drag-item {
        margin-bottom: .4rem;
        padding: .6rem .4rem;
        border-radius: .2rem;
        background-color: rgba(0, 0, 100, .1);
        color: rgba(0, 0, 0, 0.87);
        box-sizing: border-box;
        cursor: cursor;
        font-size: 14px;
    }

    .drag-item:last-child
    {
        border: none;
    }

    .drag-list.cdk-drop-list-dragging .drag-item:not(.cdk-drag-placeholder)
    {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder
{
    opacity: 0;
}

.cdk-drag-animating
{
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.alert {
    padding: 20px;
    background-color: tomato;
    color: white;
    margin: 10px;
}

.closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.closebtn:hover {
    color: black;
}

#outer-container {
    position: relative;
    margin: 0 55px;
}

#outer-container.phone {
    height: 800px;
    left: 30%;
}

#outer-container.fullscreen {
    width: 100%;
    height: 100%;
}

#simulator-container {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 24px 0;
    color: black;
    font-family: "Roboto", sans-serif;
}

.phone-img {
    position: absolute;
    max-height: 100%;
}

#android-img {
    width: 341px;
}

#screen {
    position: absolute;
}

.phone #screen {
    width: 287px;
    height: 553px;
    margin: 55px 27px;
    border-radius: 6px;
}


#ussd-box {
    position: relative;
}

.phone #ussd-box {
    width: 211px;
    padding: 22px 23px 48px 23px;
    margin: 50% auto;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 22px 38px #00000042;
    color: black;
    font-family: "Roboto", sans-serif;
}

.phone .dialog-btn, .fullscreen .dialog-btn {
    height: 36px;
    padding: 11px 17px;
    background: transparent;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    border-radius: 2px;
    border: none;
    color: #FF4C00;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media only screen and (max-width: 680px) {
    .dd-flex {
        display: block;
        margin-top: 10px;

        div {
            width: 90% !important;
            margin: 0 !important;
            margin-top: 10px !important;
        }
    }
    ::ng-deep .cdk-overlay-panel {
        height: 100vh !important;
    }
    .dd-column {
        flex-direction: column;
    }

    .mat-dialog-container {
        height: auto !important;
    }
    .layout-padding {
        padding: 0 !important;
    }
    .m-15 {
        margin: 15px 0 0 !important;
    }

    .card .card-body {
        padding: 10px !important;
    }

    .phone {
        left: 0;
    }
    .phone #screen {
        margin: 0;
        border-radius: 6px;
        width: 80%;
    }
    .phone #ussd-box {
        padding: 10px;
        margin: 0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    }
    .center {
        display: block;

    }
    #android-img {
        display: none;
    }
    #outer-container.phone {
        height: 800px;
        left: 0;
    }
}

.text-right .mat-sort-header-container {
    justify-content: flex-end;
  }




  .mat-accordion .mat-header-row {
    padding-left: 1.5rem;
    padding-right: 2rem;
    border-bottom: none;
  }

  .mat-expansion-panel-header.mat-row {
    border-bottom: none;
  }

  //copied from https://github.com/angular/material2/blob/master/src/lib/table/table.scss
  $mat-header-row-height: 56px;
  $mat-row-height: 48px;
  $mat-row-horizontal-padding: 24px;

  .mat-table {
    display: block;
  }

  .mat-header-row {
    min-height: $mat-header-row-height;
  }

  .mat-row {
    min-height: $mat-row-height;
  }

  .mat-row, .mat-header-row {
    display: flex;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    align-items: center;
    padding: 0 $mat-row-horizontal-padding;
    box-sizing: border-box;

    // Workaround for https://goo.gl/pFmjJD in IE 11. Adds a pseudo
    // element that will stretch the row the correct height. See:
    // https://connect.microsoft.com/IE/feedback/details/802625
    &::after {
      display: inline-block;
      min-height: inherit;
      content: '';
    }
  }

  .mat-cell, .mat-header-cell {
    flex: 1;
    overflow: hidden;
    word-wrap: break-word;
  }


  .custom-stepper .mat-vertical-content-container{
    margin-top: 20px;
    margin-bottom: 20px;
  }


